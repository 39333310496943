@font-face {
  font-family: "ABB Voice";
  src: url("fonts/ABBvoice_W_Rg.woff");
}

@font-face {
  font-family: "ABBVoiceBold";
  src: url("fonts/ABBvoice_W_Bd.woff");
}

.bot-bar {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  background-color: #ffffff;
  box-sizing: content-box;
  color: #222222;
  font-family: "ABBVoiceBold", sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: 0.5px;
  padding: 10px 13px 5px 13px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #dbdee1;
}

body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: "ABB Voice", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes pop {
  0% {
    opacity: 0.01;
  }

  100% {
    opacity: 1;
  }
}

html,
body {
  height: 100%
}

#root,
#root>* {
  position: absolute;
  margin-top: 0px;
  height: 100%;
  width: 100%;
}

#container {
  position: relative;
  height: 100%;
  width: 100%;
}

.content {
  overflow: visible !important;
}

.style-navigationbutton {
  font-weight: bold !important;
}

.ac-pushButton {
  background-color: white !important;
  color: black !important;
  border-radius: 0px !important;
  border: 1px solid #cdcdcd !important;
  cursor: pointer !important;
  padding: 10px;
  font-family: 'ABB Voice', sans-serif;
  font-weight: normal;
  font-size: 13px;
  white-space: normal !important;
  text-overflow: initial !important;
  flex-basis: auto !important;
  transition: border-color .5s ease, background-color .5s ease !important;
}

.ac-pushButton div {
  white-space: normal !important;
}

.ac-pushButton p {
  margin: 0;
}

.buttondisabled,
.buttondisabled:hover,
.ac-pushButton:disabled,
.ac-pushButton:disabled:hover {
  color: #cdcdcd !important;
  border: 1px solid #cdcdcd !important;
  cursor: default !important;
}

.cardDisabled .ac-textBlock {
  color: #cdcdcd !important;
}

.ac-pushButton:hover {
  border: 1px solid #222222 !important;
}

/*Change image size in carousel/global to check if we can do it only on wc-carosul */
.ac-container img {
  max-width: 100% !important;
}

.ac-adaptiveCard {
  padding: 12px !important;
}

.ac-textBlock {
  font-size: 13px !important;
}

/* webchat__suggested-action__button deprecated since 4.15.1 */
.webchat__suggested-action__button,
.webchat__suggested-action {
  cursor: pointer;
}

.webchat__bubble__content {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.webchat__bubble__content a {
  word-break: break-all;
}

.webchat__bubble {
  padding-right: 5px;
  margin-right: 0px;
  padding-bottom: 5px;
  margin-bottom: -2px;
}

.webchat__bubble--from-user {
  margin-right: -5px;
}

.ac-textBlock,
.ac-textBlock p {
  font-family: 'ABB Voice', sans-serif !important;
  text-overflow: clip !important;
  white-space: normal;
}

.webchat__basic-transcript__activity {
  animation: pop 0.5s ease !important;
}

.with-bar [role=log] {
  margin-top: 20px;
}

.webchat__typingIndicator {
  background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP////8AD//a3P/Q0//q6/8HFv8AD/8vO/+Civ9hav+6vv8jMP9LVf+Kkf9lbv++wv8nNP8FFP9PWf/m6P/Y2v/09f85RP/c3v/y8/+2u/+gpv/Kzf/u7/8AD/8AD/8ADyH5BAkLAAAAIf4aQ3JlYXRlZCB3aXRoIGFqYXhsb2FkLmluZm8AIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7') !important;
  height: 10px !important;
  width: 15px !important;
}

/* Feedback input */
textarea {
  font-size: 90%;
}

#root ::-webkit-scrollbar {
  width: 8px;
}

#root ::-webkit-scrollbar * {
  background-color: transparent;
}

#root ::-webkit-scrollbar-thumb {
  background-color: #dbdee1;
}

.botActivityDecorator {
  min-height: 60px;
  position: relative;
}

.botActivityDecorator .botActivityDecorator__buttonBar {
  list-style-type: none;
  margin: 0 0 0 10px;
  padding-left: 50px;
  padding-top: 5px;
  background: none;
}

.botActivityDecorator .botActivityDecorator__buttonBar button {
  border: none;
  border-radius: 20px;
  padding: 0px 5px 0px 5px;
  margin-left: 5px;

  transition: border-color .5s ease, background-color .5s ease !important;
}

.botActivityDecorator__buttonGreen,
.botActivityDecorator__buttonChosenGreen {
  background: #0CA919;
}

.botActivityDecorator__buttonNotChosenGreen,
.botActivityDecorator__buttonNotChosenRed {
  background: #767676;
}

.botActivityDecorator__buttonGreen:hover {
  background-color: rgb(47, 204, 60);
  cursor: pointer;
}

.botActivityDecorator__buttonRed:hover {
  background-color: rgb(255, 115, 90);
  cursor: pointer;
}

.botActivityDecorator__buttonRed,
.botActivityDecorator__buttonChosenRed {
  background: #FF000F;
}

.botActivityDecorator__buttonBar img {
  height: 12px;
  padding: 3px;
  margin-bottom: -3px;
}

.botActivityDecorator__buttonRed img,
.botActivityDecorator__buttonChosenRed img,
.botActivityDecorator__buttonNotChosenRed img {
  transform: rotate(-180deg);
}

.botActivityDecorator .botActivityDecorator__buttonBar .botActivityDecorator__label {
  font-style: normal;
  font-weight: normal;
  font-size: 80%;

  color: #767676;
}

.userAttachmentError {
  font-style: normal;
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px 0px 0px 10px;
  font-size: 90%;
  background-color: transparent;
  color: red;
}

#micModeButton {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 1;
  right: 40px;
  bottom: 0px;
  fill: #767676;
  display: flex;
  align-items: center;
  border: none;
  background-color: white;
}

#micModeButton:enabled:hover {
  fill: black;
}

#micModeButton:enabled:focus {
  outline: none;
}

#micModeButton:disabled {
  fill: #cac8c8;
}

#tooltipContainer {
  position: absolute;
  width: auto;
  height: auto;
  background-color: #424242;
  color: white;
  right: 10px;
  bottom: 50px;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
}

#tooltipContainer p {
  margin: 0;
}

#notificationContainer {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  z-index: 1;
  top: 40px;
  margin-top: 15px;
}

#notificationContainer p {
  background-color: #077911;
  border-radius: 4px;
  color: white;
  margin: 0;
  padding: 10px 20px;
  margin: 0 15px;
  text-align: justify;
}

.webchat__send-box__main.multiLanguageSendbox>form,
.webchat__send-box__main.multiLanguageSendbox>p {
  max-width: calc(100% - 90px);
  margin-left: 90px;
}

.webchat__send-box__main .dictating>span:not(:first-child) {
  /* stop showing auxiliary dictating text */
  display: none;
}

#currenLanguageBox {
  width: 90px;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  border: 0;
  padding: 0px;
  font-family: inherit;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#currenLanguageBox img {
  margin-left: 5px;
  width: 12px;
  height: 12px;
}

#languagesMenu {
  width: auto;
  height: auto;
  position: absolute;
  bottom: 25px;
  left: 0px;
  background-color: white;
  padding: 0px;
  list-style: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

#languagesMenu li {
  padding: 10px 16px;
  cursor: pointer;
}